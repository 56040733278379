<template>
  <div class="container text-center mt-5">
    <div class="row">
      <div class="col-sm-3 mb-3">
        <label for="note" class="form-label">NOTE</label>
        <input v-model="note" type="text" class="form-control" id="note" />
      </div>
      <div class="col-sm-3 mb-3">
        <label for="mempty" class="form-label">Milles Empty</label>
        <input
          v-model="mempty"
          type="number"
          class="form-control"
          id="mempty"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <label for="milles" class="form-label">Milles</label>
        <input
          v-model="milles"
          type="number"
          class="form-control"
          id="milles"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <label for="price" class="form-label">Price</label>
        <input v-model="price" type="number" class="form-control" id="price" />
      </div>
      <div class="col-sm-12 mb-3">
        <div class="d-grid gap-2">
          <button
            @click="calcPriceMi"
            class="btn btn-success btn-sm"
            type="button"
          >
            CALC
          </button>
        </div>
      </div>
    </div>
    <div v-if="xlist.length" class="w-100 mt-5">
      <hr />
      <h3 class="text-start">
        Records
        <button
          @click="trashDates"
          class="btn btn-danger btn-sm float-end"
          type="button"
        >
          Delete all
        </button>
      </h3>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">REMARK</th>
              <th scope="col">EMPTY</th>
              <th scope="col">MILLES</th>
              <th scope="col">PRICE</th>
              <th scope="col">VALUE</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in xlist" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ formatNumber(item.note) }}</td>
              <td>{{ formatNumber(item.mempty) }}</td>
              <td>{{ formatNumber(item.milles) }}</td>
              <td>{{ formatNumber(item.price) }}</td>
              <td>$ {{ item.total }}</td>
              <td>
                <button
                  @click="deleteItemDate(index)"
                  type="button"
                  class="btn btn-warning btn-sm"
                >
                  DEL
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    const note = ref(null);
    const mempty = ref(null);
    const milles = ref(null);
    const price = ref(null);
    const total = ref(0);
    const xlist = ref([]);

    onMounted(() => {
      const xdata = localStorage.getItem("mxlist_fsc");
      xlist.value = xdata ? JSON.parse(xdata) : [];
    });
    const calcPriceMi = () => {
      if (!price.value || !milles.value) {
        //here
        alert("Debe completar los campos");
      } else {
        let sum = milles.value;
        if (mempty.value) {
          sum = milles.value + mempty.value;
        }
        const aux = price.value / sum;
        total.value = Math.round((aux + Number.EPSILON) * 100) / 100;
        xlist.value.push({
          note: note.value,
          mempty: mempty.value,
          milles: milles.value,
          price: price.value,
          total: total.value,
        });
        localStorage.setItem("mxlist_fsc", JSON.stringify(xlist.value));
        note.value = null;
        mempty.value = null;
        milles.value = null;
        price.value = null;
        total.value = 0;
      }
    };
    const deleteItemDate = (index) => {
      xlist.value = xlist.value.filter((item, iter) => iter != index);
      localStorage.setItem("mxlist_fsc", JSON.stringify(xlist.value));
    };
    const trashDates = () => {
      xlist.value = [];
      localStorage.setItem("mxlist_fsc", JSON.stringify(xlist.value));
    };
    const formatNumber = (value) => {
      const aux = new Intl.NumberFormat("en-us").format(value);
      return aux;
    };
    return {
      calcPriceMi,
      deleteItemDate,
      trashDates,
      formatNumber,
      note,
      mempty,
      milles,
      price,
      total,
      xlist,
    };
  },
};
</script>
